'use client'

import { SessionProvider } from 'next-auth/react'
import { default as NextApp } from 'next/app'
import Head from 'next/head'
import { EnvProvider } from '../context/env'
import { LocaleProvider } from '../context/locale'
import { Layout as DefaultLayout } from '../layouts/management/page-layout'
import { RootLayout } from '../layouts/root-layout'

export { App, getInitialProps }

/**
 * Revalidate session every five minutes. This is in addition to every time the
 * browser is activated.
 */
const SESSION_REFETCH_INTERVAL = 300

function App(props) {
  const {
    Component,
    pageProps,
    environment,
    acceptedLocales,
    forcedTheme,
    useSessions = true,
    defaultMetadata = {},
    defaultLayout = DefaultLayout,
  } = props

  const PageLayout = Component.Layout ?? defaultLayout

  /** @type import('next').Metadata */
  const pageMetadata =
    Component.generateMetadata?.(pageProps) ?? Component.metadata

  const metadata = {
    ...defaultMetadata,
    ...pageMetadata,
    other: {
      ...defaultMetadata?.other,
      ...pageMetadata?.other,
      crumbs: [
        ...(defaultMetadata?.other?.crumbs ?? []),
        ...(pageMetadata?.other?.crumbs ?? []),
      ],
      links: [
        ...(defaultMetadata?.other?.links ?? []),
        ...(pageMetadata?.other?.links ?? []),
      ],
    },
  }

  const app = (
    <EnvProvider env={environment}>
      <LocaleProvider locale={acceptedLocales}>
        <RootLayout forcedTheme={forcedTheme}>
          <PageLayout metadata={metadata} pageProps={pageProps}>
            <Component {...pageProps} />
          </PageLayout>
        </RootLayout>
      </LocaleProvider>
    </EnvProvider>
  )

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="/images/synqly-favicon.png" rel="icon" type="image/png" />
      </Head>
      {useSessions ? (
        <SessionProvider refetchInterval={SESSION_REFETCH_INTERVAL}>
          {app}
        </SessionProvider>
      ) : (
        app
      )}
    </>
  )
}

/** @param {import('next/app').AppContext} context */
async function getInitialProps(context) {
  const initialProps = await NextApp.getInitialProps(context)

  const { req } = context.ctx

  if (req) {
    const environment = getPublicEnv(process.env)
    const acceptedLocales = getAcceptedLocales(req.headers['accept-language'])
    return { ...initialProps, acceptedLocales, environment }
  }

  return initialProps
}

function getAcceptedLocales(acceptLanguage) {
  return acceptLanguage
    ?.split(',')
    .sort((a, b) => {
      const { q: qA } = Object.fromEntries([
        a.split(';')[1]?.split('=') ?? ['q', '1'],
      ])
      const { q: qB } = Object.fromEntries([
        b.split(';')[1]?.split('=') ?? ['q', '1'],
      ])
      return Number(qB) - Number(qA)
    })
    .map((lang) => lang.split(';')[0])
}

function getPublicEnv(environment) {
  const WHITELIST = [/^NEXT_PUBLIC_.+/i, /^SYNQLY_PUBLIC_.+/i]
  const publicEnv = Object.fromEntries(
    Object.entries(environment).filter(([key]) =>
      WHITELIST.some((prefix) => RegExp(prefix).test(key)),
    ),
  )

  return publicEnv
}
