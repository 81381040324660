'use client'

import { Theme, ThemePanel } from '@radix-ui/themes'
import { ThemeProvider as ThemeSwitcher } from 'next-themes'

export { RootLayout }

function RootLayout({ forcedTheme, children }) {
  return (
    <ThemeSwitcher attribute="class" enableSystem forcedTheme={forcedTheme}>
      <Theme
        accentColor="indigo"
        grayColor="slate"
        hasBackground
        panelBackground="solid"
      >
        {process.env.NODE_ENV === 'development' && (
          <ThemePanel defaultOpen={false} />
        )}

        {children}
      </Theme>
    </ThemeSwitcher>
  )
}
