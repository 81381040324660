'use client'

import { isFun } from '@synqly/fun'
import {
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react'

export { assertDefined, createContext }

/**
 * Asserts that a given context value is defined and not null.
 *
 * @param {unknown} value The context value to check; must not be null or
 *   undefined.
 * @returns {boolean} True if the value is not null or undefined; false
 *   otherwise.
 */
function assertDefined(value) {
  return value === null || value === undefined
}

/**
 * @template T
 * @typedef {object} ContextHookOptions
 * @property {T | undefined} defaultValue The default value to use when creating
 *   the context.
 * @property {typeof assertDefined | boolean} [assertValue] If given a function,
 *   it will be used as the asserting function when retreiving the value from a
 *   context. If given a boolean the default asserter will be used if the value
 *   is `true`. The value is not checked if this value is `false`.
 */

/**
 * @template T
 * @typedef {(
 *   options?: Partial<Omit<ContextHookOptions<T>, 'defaultValue'>>,
 * ) => T} ContextHook
 */

/**
 * Creates a React context and corresponding hook to retrieve its value.
 *
 * @remarks
 *   By default the value returned from the context is asserted, meaning the
 *   configured asserter function must return a truthy value, or an error is
 *   thrown.
 * @template T
 * @param {string} name The display name of the context.
 * @param {Partial<ContextHookOptions<T>>} [defaultOptions] Default options used
 *   by the returned context hook.
 * @returns {[React.Context<T>, ContextHook<T>]}
 */
function createContext(name, defaultOptions) {
  const displayName = name.replace(/Context$/i, '')

  const context = createReactContext(defaultOptions?.defaultValue)
  context.displayName = `${displayName}Context`
  Object.defineProperty(useContext, 'name', {
    value: `use${displayName}`,
  })

  return [context, useContext]

  /** @type {ContextHook<T>} */
  function useContext(options) {
    const value = useReactContext(context)
    const assertValue = options?.assertValue ?? defaultOptions?.assertValue

    if (assertValue) {
      const assert = isFun(assertValue)
        ? /** @type {typeof assertDefined} */ (assertValue)
        : assertDefined

      if (!assert(value)) {
        throw new TypeError(
          `${context.displayName} was not found or is misconfigured.`,
          { cause: context },
        )
      }
    }
    return value
  }
}
